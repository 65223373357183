// let currentBoard = null

export default class {
  execute(spec, component) {
    const t = TrelloPowerUp.iframe()

    // Allow testing outside of Trello, i.e. when board button doesn't exist.
    this.constructor.resetData(t)

    $tpu.r.data.setDisplayMode(spec.displayMode)

    this.constructor._launchBoardWithAuthHeaders(t, component)
  }

  static resetData(t) {
    if (GLib.settings.isDev) {
      // Uncomment for testing
      // $tpu.r.data.get(t, "board", "shared").then((data) => {
      //   console.debug("Board data1", data, JSON.stringify(data).length)

      //   // $tpu.r.data.remove(t, "board", "shared", [
      //   //   "p:11ae293e-1c46-4476-ac29-83697ce1cc19",
      //   //   "p:8f97af7a-3add-4f9a-9187-222d2356d989",
      //   //   "",
      //   //   "",
      //   //   "",
      //   // ]).then((data) => {
      //   //   $tpu.r.data.get(t, "board", "shared").then((data) => {
      //   //     console.debug("Board data2", data, JSON.stringify(data).length)
      //   //   })
      //   // })
      // })

      //   // projects.prepopulateDefaults(t)
      // $tpu.r.data.remove(t, "member", "private", $tpu.keys.mbrAuthToken())
    }
  }

  static async _launchBoardWithAuthHeaders(t, component) {
    const [project, _token, _board] = await Promise.all([
      $tpu.r.projects.lastViewed(t),

      // These two pieces of data are needed to make the request below.
      $tpu.r.data.preloadOauthToken(t),
      $tpu.urls.preloadBoard(t)
    ])

    // This request will include the necessary authorization header. See `settings.headerAugmenter`
    GLib.action.execute({
      "action": "windows/open",
      "url": project ? $tpu.urls.project(project.id) : $tpu.urls.projects(),
    }, component)
    return
  }

  static dashboardTitle() {
    return 'Shape Up Dashboard'
  }

  static launchAuthorize(t) {
    // // Don't use a boardBar due to an issue caused by reusing a boardBar. For some reason, the action icons of
    // // the new boardBar will not do anything when clicked.
    // return t.modal({
    //   title: this.dashboardTitle(),
    //   url: '/authorizations/new',
    //   height: 220
    // });

    return t.boardBar({
      title: this.dashboardTitle(),
      url: '/authorizations/new',
      height: 180,
      actions: []
    });
  }

  static _launchBoard(t, board, largeMode) {
    // const autoLaunch = localStorage.getItem($tpu.keys.lclBoardAutoLaunch(board.id))
    // 540 -> enough to show hill chart + scope headers
    // 120 -> enough to show hill chart
    const height = largeMode ? 540 : 180

    return t.boardBar({
      title: this.dashboardTitle(),
      url: '/authorizations?view=embedded',
      height: height,
      resizable: true,
      actions: [
        {
          icon: $tpu.icons.expand,
          alt: "Open in full window",
          position: 'left',
          callback: (tr) => tr.modal({
            title: this.dashboardTitle(),
            url: '/authorizations?view=standalone',
            fullscreen: true,
          }),
        },
        {
          icon: $tpu.icons.settings,
          alt: "Change launch settings",
          position: 'left',
          callback: (tr) => {
            tr.modal({
              title: "Settings",
              url: `/boards/${board.id}/settings/shapeup/edit`,
              height: 240
            })
          }
        }
      ]
    });
  }

  static async conditionalLaunch(t, board, largeMode) {
    // currentBoard = board

    const token = await $tpu.r.data.preloadOauthToken(t)
    if (token) {
      return this._launchBoard(t, board, largeMode)
    }
    return this.launchAuthorize(t)
  }

  static async requestRelaunch(t, launchMode) {
    // Make sure boardBar is launched by the containing Trello board (instead of within an iframe, e.g. boardBar
    // or modal) to address a boardBar action icon issue whereby the icons don't do anything when clicked.
    $tpu.r.data.freshUpdateMemberBoardData(t, (data) => {
      data.oneOffLaunch = launchMode
    })
  }
}
